.MrMenu {
  //background-color: rgba(255,255,255,0.87);
  font-size: 1rem;
  height: 100vh;
  min-width: 10rem;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  overflow-y: scroll;
  overscroll-behavior-y: contain;

  /* Hide scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar { /* Chrome, Safari and Opera */
    display: none;
  }

  @media screen and (min-width: 550px) {
    width: 16rem;
    max-width: 50%;
  }

  @media screen and (max-width: 549px) {
    width: 70%;
    box-shadow: 0.125rem 0 1rem rgba(0, 0, 0, 0.5);
  }

  .MenuContainer {
    position: absolute;
    right: 0.4rem;
    width: calc(100% - 0.4rem);
    height: calc(100vh - 12rem + 5.2rem);
  }

  .menuHeader {
    width: 100%;
    height: 12rem;
    max-height: 30%;
    position: relative;
    text-align: center;
    background: linear-gradient(to left, #0066ff, #00ffbf, #b3ff19);

    span {
      position: absolute;
      right: 0;
      bottom: 0;
      margin: 0.4rem;
      //color: white;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0.5rem 0;
    width: 100%;
  }

  ul li {
    margin: 0;
    padding: 0.5rem;
    display: block;
    //color: rgba(0,0,0,0.87);
    background: transparent;
  }

  // ul li.active {
  //   color: #2196f3;
  //   background: #ddd;
  // }

  .menuFooter {
    padding: 0 0.5rem;
    line-height: 1.2rem;
    position: absolute;
    bottom: 4rem;
  }

  .copyright {
    //color: rgba(0,0,0,0.87);
    white-space: nowrap;
  }
}
