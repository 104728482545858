.BannerWrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  box-sizing: border-box;
}

.BannerView {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  transition: height 1s;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  background: rgba(13, 13, 13, 0.55);
  box-sizing: border-box;

  .con {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(255, 255, 255, 0.8);

    span {
      font-size: 2.718rem;
    }

    i {
      font-size: 1.414rem;
    }
  }

  .bottom {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%, 0);
  }
}