.FabWrapper {
  .fabView {
    //background: #FF4081;
    width: 3.5rem;
    height: 3.5rem;
    text-align: center;
    line-height: 3.5rem;
    border-radius: 50%;
    filter: drop-shadow(0.1rem 0.1rem 0.2rem rgba(112, 112, 112, 0.7));
  }

  .fabView .iconWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .iconWrapper svg {
    width: 1.5rem;
    height: 1.5rem;
    //fill: white;
    display: block;
    margin: 0 auto;
    transition: transform 200ms;
  }

  .fabMenu {
    position: absolute;
  }

  .fabMenu div {
    position: absolute;
    //background: #66ccff;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    line-height: 2.5rem;
    border-radius: 50%;
    filter: drop-shadow(0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.5));
    display: none;
  }
}