.toolbarShadow {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
}

.ToolbarView {
  composes: toolbarShadow;
  width: 100%;
  height: 3.125rem;
  //position: fixed;
  //top: 0;
  z-index: 104;

  canvas {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
}

.Title {
  font-size: 1.25rem;
  line-height: 3.125rem;
  float: left;

  a {
    //color: white;
  }
}

.IconFather {
  display: block;
  width: 3.125rem;
  height: 3.125rem;
  font-size: 1.25rem;
  text-align: center;
  line-height: 3.125rem;
  transition: all .5s;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    vertical-align: middle;
    dominant-baseline: middle;
  }
}

.MenuIcon {
  composes: IconFather;
  float: left;
  //color: white;
}

.SearchIcon {
  composes: IconFather;
  float: right;
  //color: white;
}

.SearchView {
  composes: toolbarShadow;
  //background: rgba(255,255,255,0.6);
  border-radius: 0 0 0 1rem;
  font-size: 1rem;
  text-align: center;
  height: 3.125rem;
  width: 100%;
  min-width: 15rem;
  max-width: 20rem;
  position: fixed;
  //top: 3.125rem;
  right: 0;
  z-index: 104;
  display: none;

  input {
    width: 85%;
    height: 1.875rem;
    margin: 0.625rem 1rem;
    border: none;
    background: transparent;
    //box-shadow: 0 0.125rem grey;
  }

  input:focus {
    //box-shadow: 0 0.125rem #FF4081;
    outline: none;
  }
}